const _ = require('lodash');

const GLASS_SPECIFIC_HEAT = 0.753;    // Joules per (C * g)
const WATER_SPECIFIC_HEAT = 4.184     // Joules per (C * g)
const ETHANOL_SPECIFIC_HEAT = 0.0     // Joules per (C * g)

const JOULES_TO_MELT_1G_ICE = 334;

// TODO calculate
const GLASS_LIQUID_HA = 1.0;         // TODO measure
const ICE_LIQUID_HA = 1.0;
const AIR_LIQUID_HA = 0.055;         // in (J / s * T)

const GLASS_MASS = 320;  // grams


class Point {
    liquidT;            // °C
    iceT;               // °C
    glassT;             // °C
    iceMass;            // grams
    initialLiquidMass;  // grams
    addedWaterMass;      // grams
    totalJoulesFromEnv = 0;
    time = 0;

    constructor(point) {
        this.liquidT = point.liquidT
        this.iceT = point.iceT
        this.glassT = point.glassT
        this.iceMass = point.iceMass
        this.initialLiquidMass = point.initialLiquidMass
        this.addedWaterMass = point.addedWaterMass
        this.time = 0;
        this.totalJoulesFromEnv = 0
    }

    addedWaterPercentage() {
        return 100.0 * this.addedWaterMass / (this.initialLiquidMass);
    }

    //function percentAbv() {
    //    return 0.0;
    //}
}

class CocktailModel {
    constructor(initial) {
        this.initial = initial;
    }

    simulate(totalTime) {
        this.points = [this.initial];
        var cur = this.initial;
        cur.time = 0;

        for (var t = 0; t < 60; t++) {
            cur = this.nextPoint(cur);
            this.points.push(cur);
        }

        return this;
    }

    toChartData() {
        return {
            labels: _.map(this.points, (p) => p.time),
            datasets: [{
                label: 'Temperature',
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: _.map(this.points, (p) => p.liquidT),
            },
            {
                label: 'Water %',
                backgroundColor: 'rgb(99, 132, 255)',
                borderColor: 'rgb(99, 132, 255)',
                data: _.map(this.points, (p) => p.addedWaterPercentage())
            },
            ]
        }
    }

    nextPoint(cur) {
        var next = new Point(cur)

        const liquidToGlassJoules = this.newtonsLaw(cur.liquidT, cur.glassT, GLASS_LIQUID_HA);
        const liquidToIceJoules = Math.max(0, this.newtonsLaw(cur.liquidT, cur.iceT, ICE_LIQUID_HA));
        const liquidToEnvJoules = this.newtonsLaw(cur.liquidT, 20, AIR_LIQUID_HA);

        const liquidSpecificHeat = this.liquidSpecificHeat(cur);
        const liquidMass = cur.initialLiquidMass + cur.addedWaterMass;

        next.liquidT -= (liquidToGlassJoules + liquidToIceJoules + liquidToEnvJoules) / (liquidSpecificHeat * liquidMass);
        next.glassT += liquidToGlassJoules / (GLASS_SPECIFIC_HEAT * GLASS_MASS);
        next.iceT = 0; // TODO - model temperature of ice
        next.totalJoulesFromEnv = cur.totalJoulesFromEnv + -liquidToEnvJoules;

        next.iceMass -= liquidToIceJoules / JOULES_TO_MELT_1G_ICE;
        next.addedWaterMass += liquidToIceJoules / JOULES_TO_MELT_1G_ICE;
        next.time = cur.time + 1;

        return next;
    }

    liquidSpecificHeat(point) {
        const waterPercentage = 0.0;
        // TODO model ABV
        return WATER_SPECIFIC_HEAT;
    }

    /**
     * @returns heat transfer from object to env. If env is hotter than object,
     * this number will be negative; if colder, this will be positive.
     */
    newtonsLaw(objectTemp, envTemp, hA) {
        return (objectTemp - envTemp) * hA * 60;
    }
}

module.exports = {CocktailModel, Point}