/**
 * Consider:
 *   Metrics-driven teams vs machine learning teams? Could be split.
 *   
 *   -- Eval --
 *     Offline vs online. Conistent code.
 *     Launch criteria (metric).
 *     Feedback loop.
 *
 *   -- Team Structure --
 *     Management ratios. Can be very high.
 *     Product, project, or program managers. Designers.
 *     De-composition by data source.
 *
 *
 */

 import React from "react"
 import { Link } from "gatsby"
 import _ from "lodash"
 import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

 import BlogPost from "../components/layout"
 import Layout from "../components/layout"
 import Remark from "../components/remark"
 import Image from "../components/image"
 import SEO from "../components/seo"
 
 import {CocktailModel, Point} from "../util/cocktail-model"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
        y: {
            suggestedMin: -7,
            suggestedMax: 8
        }
    }
};

const labels = ['0mins', '5mins', '10mins', '20 mins', '30 mins', '45 mins']

const data = {
    labels: labels,
    datasets: [{
        label: 'Temperature',
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        data: [0, 10, 5, 2, 20, 30, 45],
    },
    {
        label: 'Water %',
        backgroundColor: 'rgb(99, 132, 255)',
        borderColor: 'rgb(99, 132, 255)',
        data: [0, 32, 10, 5, 2, 20, 30, 45],
    },
    ]
}

const BUILT = new Point({
    liquidT: 7,
    iceT: 0,
    glassT: 20,

    iceMass: 164,
    initialLiquidMass: 85,
    addedWaterMass: 5,
});

const STIRRED = new Point({
    liquidT: -5,
    iceT: 0,
    glassT: -10,

    iceMass: 164,
    initialLiquidMass: 85,
    addedWaterMass: 5,
});

/**
 * Plan:
 *   Generate points (over 45 minutes) for various starting points.
 * 
 * 
 * 
 * @returns 
 */
const NegroniScience = () => {

    const built = new CocktailModel(BUILT).simulate(60)
    const stirred = new CocktailModel(STIRRED).simulate(60)    

    const builtData = built.toChartData();

    return (
   <Layout>
     <div className='blog'>
       <SEO title="Negroni Science" />
 
       <h1>Negroni Science</h1>
 
        This post is inspired by Dave Arnold's&nbsp;
        <a href='http://www.cookingissues.com/index.html%3Fp=4585.html'>
            the science of stirring cocktails
        </a>,

        but I wanted to make a few additions.
        
        First off, I wanted to compare several techniques in use at bars today -- building in glass? stirring --.

        Second, to include subjective elements like aroma, flavor, and texture / viscocity.

        Last, to model adaptations.


       <h2>The initial experiment: built, stirred, short-stirred</h2>

        <ul>
            <li>
               <b>Built in glass</b>. 
            </li>
            <li>
                  <b>Stirred over cracked ice</b>. 
            </li>
            <li>
                <b>Briefly stirred</b>. 
            </li>
        </ul>

        <table>

        </table>


        <h2>Newton's Law of Cooling and Specific Heat</h2>

        <p>
            <i>
                Note: the below is a little technical. If you just want the results, skip
                ahead to the next section.
            </i>
        </p>


        We can model what's happening inside of the glass using two laws of physics:
        <ul>
            <li>
                <b>Newton's law of cooling</b> says the speed at which something heats or cools
                is proportional to the difference in temperature with its environment:
                <p>
                  <b>&#916;T</b> = h &#x2219; A &#x2219; (T - T<sub>env</sub>)
                </p>
                In the above equation, T is temperature of the object, &#916;T is the rate of change of its
                temperature, and T<sub>env</sub> is the temperature of its environment. h and A are constants
                that affect how quickly heat flows being the object of interest and its environment;
                we'll measure them later.
            </li>
            <li>
                    <b>
                        It takes 90x more energy to melt ice than to warm the cocktail by 1°C.&nbsp;
                    </b>

                    Melting 1 gram of ice requires 334 joules of energy (this is the <i>latent heat of fusion</i>).
                    Since the specific heat
                    of water is 4.184 and the specific heat of pure alcohol is 2.46,
                    a negroni (which is about 24% ABV) will have a specific heat of 3.77.
                    In other words, warming 1 gram of a negroni by 1 degree celcius absorbs 3.77 joules of energy.
                    Translating this to a realistic scenario, cooling a typical 3oz (85-gram) cocktail from room
                    temperature (20°C) to 0°C would require melting 19g of ice, which is about 11% of a 2" x 2" x 2.5"
                    ice cube.
            </li>
        </ul>

        <p>
            In the case of a cocktail, these two laws are applied multiple times since there are three interacting
            systems: the liquid in the glass, the temperature of the glass itself, and the melting of the ice cube.
        </p>

        <p>
            For the liquid in the glass, its environment is both the glass (which cools it) and the environment
            (which warms it), so Newton's law is applied to each of those systems. The liquid is also being
            cooled by the melting of the ice, where each gram 
        </p>


        The liquid in the glass is being cooled by the glass itself and warmed by the room according to Newton's law,
        so that law is applied twice. Plus, the liquid in the glass is being.

        <div>
            <b>T&#775;</b><sub>negroni</sub>
            &nbsp;=&nbsp;
            Ha<sub>1</sub> * (<b>T</b><sub>negroni</sub> - <b>T</b><sub>room</sub>)
            &nbsp;+&nbsp;
            Ha<sub>2</sub> * (<b>T</b><sub>negroni</sub> - <b>T</b><sub>glass</sub>)
        </div>

        Technically, there are a few effects we're ignoring in the equations above. The ice itself may be
        below freezing.


        <h2>Results</h2>

        [Compare two techniques]

        <div>
            <div>
                Built
            </div>

            <div>
                Stirred
            </div>

            <div>
                Presets
                [Built, stirred down, stirred up]

                Serving glass - Frozen, Room Temp
                Serving glass - Coupe, Double Old Fashioned

                Stirred in frozen mixing glass till
                    [20°F, 25°F, 30°F, 35°F]

                Ice Size - [2 x 2 x 2.5"],  [2 x 2 x 2], [1 x 1 x 1]

            </div>
            <Line options={options} data={builtData} />;
        </div>

        <table style={{cellSpacing: 10, cellPadding: 10}}>
            <tr>
                <th>time (mins)</th>
                <th>built (temp)</th>
                <th>built (water)</th>
                <th>stirred (temp)</th>
                <th>stirred (water)</th>
            </tr>

            {
                _.map(_.range(0, 60), (t) => {
                    return <tr>
                        <td>{t}</td>
                        <td>{Math.round(built.points[t].liquidT)}</td>
                        <td>{Math.round(built.points[t].addedWaterMass)}</td>
                        <td>{Math.round(built.points[t].totalJoulesFromEnv)}</td>      

                        <td>{Math.round(stirred.points[t].liquidT)}</td>
                        <td>{Math.round(stirred.points[t].addedWaterMass)}</td>
                        <td>{Math.round(built.points[t].totalJoulesFromEnv)}</td>                              
                    </tr>
                })
            }
        </table>
     </div>
   </Layout>);
}
 
 export default NegroniScience
 